export interface ModalProps {
  children?: React.ReactNode;
  onClose?: () => void;
}

export const Modal: React.FC<ModalProps> = ({ children, onClose }) => {
  return (
    <div className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen backdrop-blur-md">
      <div className="relative w-3/4 p-5 shadow-2xl bg-secondary rounded-xl">
        {onClose && (
          <div
            className="absolute flex items-center justify-center w-6 h-6 text-sm rounded-full cursor-pointer top-2 right-2 bg-neutral"
            onClick={onClose}
          >
            X
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
