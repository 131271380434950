function getEnvVariable(name: string) {
  return process.env[name];
}

const envParsers = {
  POWERED_BY: (value: any): "KNOBS" | "BCode" | "both" => {
    switch (value) {
      case "KNOBS":
        return "KNOBS";
      case "both":
        return "both";
      default:
        return "BCode";
    }
  },
};

function parseEnvVariable(name: keyof typeof envParsers, value: any) {
  return envParsers[name](value);
}

export const envs = {
  ALCHEMY_API_KEY: getEnvVariable("REACT_APP_ALCHEMY_API_KEY") || "",
  OPENSEA_URL: getEnvVariable("REACT_APP_OPENSEA_URL"),
  EXPLORER_URL: getEnvVariable("REACT_APP_EXPLORER_URL"),
  CHAIN: getEnvVariable("REACT_APP_CHAIN"),
  IPFS_GATEWAY: getEnvVariable("REACT_APP_IPFS_GATEWAY"),
  FIREBASE_API_KEY: getEnvVariable("REACT_APP_FIREBASE_API_KEY"),
  FIREBASE_AUTH_DOMAIN: getEnvVariable("REACT_APP_FIREBASE_AUTH_DOMAIN"),
  FIREBASE_PROJECT_ID: getEnvVariable("REACT_APP_FIREBASE_PROJECT_ID"),
  FIREBASE_STORAGE_BUCKET: getEnvVariable("REACT_APP_FIREBASE_STORAGE_BUCKET"),
  FIREBASE_MESSAGING_SENDER_ID: getEnvVariable(
    "REACT_APP_FIREBASE_MESSAGING_SENDER_ID"
  ),
  FIREBASE_APP_ID: getEnvVariable("REACT_APP_FIREBASE_APP_ID"),
  ALCHEMY_URL: getEnvVariable("REACT_APP_ALCHEMY_URL"),
  POWERED_BY: parseEnvVariable(
    "POWERED_BY",
    getEnvVariable("REACT_APP_POWERED_BY")
  ),
  // THEME_TAG: getEnvVariable("REACT_APP_THEME_TAG") || "main",
  APP_TITLE: getEnvVariable("REACT_APP_APP_TITLE") || "Walletplace by BCode",
};
