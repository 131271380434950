import { useTranslation } from "react-i18next";
import { Section } from "../components/layout/Section";

interface MissingKeyProps {}

export const MissingKey: React.FC<MissingKeyProps> = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex overlay bg-opacity-60" />
      <div className="flex flex-col items-center justify-center w-full text-center text-primary">
        <Section>
          <p className="mb-5">{t("missing_key.title")}</p>
        </Section>
        <div className="flex flex-col items-center justify-center">
          {t("missing_key.text")}
        </div>
      </div>
    </>
  );
};
