import { Wallet } from "ethers";
import { useCallback, useMemo, useState } from "react";
import { useLocalStorage } from "react-use";

export function useWallet() {
  const [value, setValue, remove] = useLocalStorage("mnemonic", "");
  const [isLoading, setIsLoading] = useState(false);

  const wallet = useMemo(
    () => (value ? Wallet.fromMnemonic(value) : null),
    [value]
  );

  const saveWallet = useCallback(
    (wlt: Wallet) => {
      setValue(wlt.mnemonic.phrase);
    },
    [setValue]
  );

  const createWallet = useCallback(async () => {
    setIsLoading(true);

    // Wrapped in a Promise to not block the UI
    const generateRandomWallet = () =>
      new Promise<string>((resolve) =>
        setTimeout(() => resolve(Wallet.createRandom().mnemonic.phrase), 500)
      );

    const mnemonic = await generateRandomWallet();

    setValue(mnemonic);

    setTimeout(() => setIsLoading(false), 500);
  }, [setValue]);

  return { wallet, saveWallet, clearWallet: remove, createWallet, isLoading };
}
