import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  FacebookShareButton,
  FacebookIcon,
} from "react-share";
import { DropConfig } from "../utils/types";

interface NFTCardProps {
  name: string;
  description: string;
  url: string;
  video?: string;
  downloadLink: string;
  cta?: React.ReactNode;
  openseaLink?: string;
  shareOptions?: DropConfig["shareOptions"];
}

export const NFTCard: React.FC<NFTCardProps> = ({
  name,
  description,
  url,
  downloadLink,
  video,
  cta,
  openseaLink,
  shareOptions,
}) => {
  const { t } = useTranslation();

  const [showVideo, setShowVideo] = useState(false);

  const handleShowVideo = useCallback(() => {
    setShowVideo((prev) => !prev);
  }, []);

  return (
    <div className="relative block overflow-hidden rounded-lg border border-gray-100 p-8shadow-xl shadow-lg">
      <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>
      <div>
        {showVideo && (
          <video src={video} className="w-full h-full" autoPlay controls />
        )}
        {!showVideo && (
          <figure>
            <img src={url} alt="nft" className="w-full h-full" />
          </figure>
        )}
      </div>
      {video && (
        <div onClick={handleShowVideo} className="mt-2 mb-2 btn btn-sm">
          {showVideo ? t("nft.switch_to_gif") : t("nft.switch_to_video")}
        </div>
      )}
      <div className="flex items-center card-body">
        <h2 className="card-title">{name}</h2>
        <p style={{ whiteSpace: "pre-wrap" }}>{description}</p>
        {downloadLink && (
          <a href={downloadLink} target="_blank" rel="noreferrer">
            {t("nft.download_nft")}
          </a>
        )}
        {cta}
      </div>
      <div className="flex items-center justify-center card-footer mb-10">
        {openseaLink && (
          <a
            className="btn outline-none border-none"
            style={{ backgroundColor: "rgb(32, 129, 226)" }}
            target="_blank"
            rel="noreferrer"
            href={openseaLink}
          >
            <img
              alt="OpenSea Logo"
              src="https://opensea.io/static/images/logos/opensea.svg"
              className="w-8 h-8 mr-2"
            />
            {t("nft.view_on_opensea")}
          </a>
        )}
      </div>
      {openseaLink && shareOptions?.length && (
        <div className="flex flex-col items-center justify-center card-footer mb-10 h-12">
          <h3 className="mb-2 text-xl">{t("nft.share")}</h3>
          <div className="flex flex-row w-full justify-around h-10">
            {shareOptions?.map((option) => {
              switch (option.social) {
                case "linkedin":
                  return (
                    <LinkedinShareButton url={openseaLink} className="h-full">
                      <LinkedinIcon className="h-full" />
                    </LinkedinShareButton>
                  );
                case "twitter":
                  return (
                    <TwitterShareButton url={openseaLink} className="h-full">
                      <TwitterIcon className="h-full" />
                    </TwitterShareButton>
                  );
                case "whatsapp":
                  return (
                    <WhatsappShareButton url={openseaLink} className="h-full">
                      <WhatsappIcon className="h-full" />
                    </WhatsappShareButton>
                  );
                case "telegram":
                  return (
                    <TelegramShareButton url={openseaLink} className="h-full">
                      <TelegramIcon className="h-full" />
                    </TelegramShareButton>
                  );
                case "facebook":
                  return (
                    <FacebookShareButton url={openseaLink} className="h-full">
                      <FacebookIcon className="h-full" />
                    </FacebookShareButton>
                  );
                default:
                  return null;
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
};
