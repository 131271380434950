import { Wallet } from "ethers";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalProps } from "./Modal";
import { saveAs } from "file-saver";

interface DownloadWalletModalProps extends ModalProps {}

export const DownloadWalletModal: React.FC<DownloadWalletModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = useCallback(async () => {
    const mnemonic = localStorage.getItem("mnemonic");
    if (!mnemonic) return;
    const wallet = Wallet.fromMnemonic(JSON.parse(mnemonic));

    setIsLoading(true);
    const encrypted = await wallet.encrypt(password);

    const blob = new Blob([encrypted], {
      type: "text/plain;charset=utf-8",
    });

    saveAs(blob, "wallet.json");
    setIsLoading(false);

    props?.onClose && props?.onClose();
  }, [password, props]);

  return (
    <Modal {...props}>
      <div className="flex flex-col items-center justify-center">
        <div className="mt-2 mb-2 text-2xl text-black">
          {t("download_wallet.title")}
        </div>
        <input
          type="text"
          placeholder={t("download_wallet.placeholder")}
          className="w-full max-w-xs p-4 mt-2 mb-2 text-black bg-white rounded-md"
          style={{ borderWidth: "1px", borderColor: "black" }}
          onChange={(event) => setPassword(event.target.value)}
        />

        <button
          className={`mt-2 mb-2 btn btn-success ${
            password.length < 5 || isLoading ? "btn-disabled text-black" : ""
          } ${isLoading ? "loading" : ""}`}
          onClick={handleDownload}
        >
          {isLoading
            ? t("download_wallet.encrypting")
            : t("download_wallet.button")}
        </button>
      </div>
    </Modal>
  );
};
