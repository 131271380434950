import { useTranslation } from "react-i18next";
import { Section } from "../components/layout/Section";

interface InvalidEventProps {}

export const InvalidEvent: React.FC<InvalidEventProps> = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex overlay bg-opacity-60" />
      <div className="flex flex-col items-center justify-center w-full text-center text-primary">
        <Section>
          <p className="mb-5">{t("invalid_event.title")}</p>
        </Section>
        <div className="flex flex-col items-center justify-center"></div>
      </div>
    </>
  );
};
