import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/layout/Section";
import { useTranslation } from "react-i18next";
import { useDropConfig } from "../hooks/useDropConfig";
import { useCallback } from "react";

interface DigitalPassportPageProps {}

export const DigitalPassportPage: React.FC<DigitalPassportPageProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { event } = useParams();

  const { dropConfig, isFetched } = useDropConfig(event);
  const handleBack = useCallback(() => navigate(-1), []);

  if (isFetched && !dropConfig.hasPassport) {
    return <Navigate replace to={`/wallet`} />;
  }

  return (
    <>
      <div className="flex flex-col items-center w-full text-cente text-primary">
        <Section>
          <div className="justify-center tabs tabs-boxed align-center text-secondary">
            <span className="text-xl">{t("passport.title")}</span>
          </div>
        </Section>
        <Section>
          {dropConfig?.passportInfo.length ? (
            <table className="table-auto">
              <tbody>
                {dropConfig?.passportInfo.map((info: any, key: number) => (
                  <tr className="text-left" key={key}>
                    <th>{info.title}</th>
                    <td>{info.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}
        </Section>
        <Section>
          <button className="btn btn-primary" onClick={handleBack}>
            {t("common.back")}
          </button>
        </Section>
      </div>
    </>
  );
};
