import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useParams } from "react-router-dom";
import { useAccount, useConnect } from "wagmi";
import { Section } from "../components/layout/Section";
import { Loader } from "../components/Loader";
import { useDropConfig } from "../hooks/useDropConfig";
import { useProof } from "../hooks/useProof";
import { useRight } from "../hooks/useRight";
import { useWallet } from "../hooks/useWallet";
import { useDynamicTranslations } from "../hooks/useDynamicTranslations";
import { useLocalStorage } from "react-use";

interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  const { wallet, createWallet, isLoading } = useWallet();
  const { connect, connectors } = useConnect();
  const { connector: activeConnector } = useAccount();
  const { event, key } = useParams();
  const { t } = useTranslation();

  const { dropConfig, isFetched: dropConfigFetched } = useDropConfig(event);

  const { isFetched: translationsFetched } = useDynamicTranslations(event);

  const [storedEmail] = useLocalStorage("email", "none");

  const {
    right,
    error: keyError,
    isLoading: keyLoading,
    isFetched: redeemedFetched,
    redeemed,
  } = useRight(key, dropConfig?.contractAddress, dropConfig?.tokenId);

  const proofData = useProof(right, dropConfig?.rights, event);

  const handleCreateWallet = useCallback(async () => {
    if (!wallet) {
      await createWallet();
    }

    try {
      connect({ connector: connectors[2] });
    } catch (e) {
      console.log("error", e);
    }
  }, [wallet, createWallet, connect, connectors]);

  const ConnectorButtons = useMemo(
    () =>
      connectors
        .filter(
          (connector) => connector.name !== "Browser Wallet" && connector.ready
        )
        .map((connector) => (
          <Section key={connector.name}>
            <div>
              <div
                className="btn btn-primary"
                onClick={() =>
                  !activeConnector && connect({ connector: connector })
                }
              >
                {connector.name}
              </div>
            </div>
          </Section>
        )),
    [connectors, activeConnector, connect]
  );

  if (
    dropConfig?.requireEmail &&
    dropConfigFetched &&
    !key &&
    storedEmail === "none"
  ) {
    return <Navigate replace to={`/${event}/email`} />;
  }

  // If the event does not exist, redirect to the invalid event page
  if (
    translationsFetched &&
    event &&
    dropConfigFetched &&
    !dropConfig?.contractAddress
  )
    return <Navigate replace to={`/invalid-event`} />;

  // If the wallet is connected and the right has not yet been used
  if (
    translationsFetched &&
    activeConnector &&
    right &&
    redeemedFetched &&
    !redeemed
  )
    return <Navigate to={`/${event}/${key}/claim`} />;

  // If the wallet is connected and the right has already been used
  if (
    translationsFetched &&
    activeConnector &&
    right &&
    redeemedFetched &&
    redeemed
  )
    return <Navigate to={`/${event}/${key}/wallet`} />;

  console.log("keyError", keyError);
  // If the provided right is invalid, redirect to the invalid key page
  if (
    translationsFetched &&
    key &&
    (keyError || proofData?.proof?.length === 0)
  )
    return <Navigate replace to={`/invalid-key`} />;

  if (translationsFetched && !key && activeConnector && event)
    return <Navigate replace to={`/${event}/wallet`} />;

  // // If no key has been detected
  // if (translationsFetched && !key)
  //   return <Navigate replace to={`/missing-key`} />;

  return (
    <>
      {(!translationsFetched || isLoading || keyLoading) && <Loader />}
      <div className="flex overlay bg-opacity-60" />
      <div className="flex flex-col items-center justify-center w-full text-center text-primary">
        {/* <Section>
          <p className="mb-5">{t("home.title")}</p>
        </Section> */}
        <div className="flex flex-col items-center justify-center">
          <Section>
            <h3 className="mb-5 text-2xl font-bold">
              {!wallet && !isLoading
                ? t("home.dont_have_a_wallet")
                : t("home.wallet_present_connect")}
            </h3>
          </Section>
          <Section>
            <div>
              <button className="btn btn-primary" onClick={handleCreateWallet}>
                {!wallet && !isLoading
                  ? t("common.create_wallet")
                  : t("common.connect_wallet")}
              </button>
            </div>
          </Section>
          <Section>
            <h3 className="mb-5 text-2xl font-bold">
              {wallet
                ? t("home.connect_wallet_alternative")
                : t("home.connect_wallet")}
            </h3>
          </Section>
          {ConnectorButtons}
        </div>
      </div>
    </>
  );
};
