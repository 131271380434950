import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/layout/Section";
import { Loader } from "../components/Loader";
import { useTranslation } from "react-i18next";
import { z } from "zod";
// import axios from "axios";
import { PrivacyPolicyModal } from "../components/PrivacyPolicyModal";
import { useDropConfig } from "../hooks/useDropConfig";
import { useEmailSender } from "../hooks/useEmailSender";
import { useEmailWatcher } from "../hooks/useEmailWatcher";
import { RequestStatus } from "../utils/types";
import { toast } from "react-toastify";

interface EmailPageProps {}

const emailValidation = z.string().min(1).email();

export const EmailPage: React.FC<EmailPageProps> = () => {
  const navigate = useNavigate();
  const { event } = useParams();
  const { t } = useTranslation();

  const { dropConfig } = useDropConfig(event);

  const [email, setEmail] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const { sendEmail, isSuccess, isLoading } = useEmailSender(
    event,
    email,
    privacy
  );

  const { data } = useEmailWatcher(event, email, isSuccess);

  useEffect(() => {
    if (data?.status === RequestStatus.Success && data?.url) {
      const url = new URL(data.url);
      navigate(url.pathname);
    }
  }, [data?.status, data?.url, navigate]);

  const handleEmailSend = useCallback(async () => {
    if (isLoading || !sendEmail) return;

    sendEmail().catch((e) =>
      toast.error(t("email.error") as string, { autoClose: 5000 })
    );
  }, [sendEmail, isLoading, t]);

  const isValid = emailValidation.safeParse(email).success;

  return (
    <>
      {(isLoading || data?.status == RequestStatus.Queued) && <Loader />}
      {isPrivacyModalOpen && (
        <PrivacyPolicyModal
          policy={dropConfig.privacyPolicy}
          onClose={() => setIsPrivacyModalOpen(false)}
        />
      )}
      <div className="flex overlay bg-opacity-60" />
      <div className="flex flex-col items-center w-full text-cente text-primary">
        <div className="flex flex-col items-center justify-center ">
          <Section>
            <h3 className="mb-5 text-3xl font-bold">{t("email.text")}</h3>
          </Section>
          <Section>
            <input
              type="text"
              placeholder={t("email.placeholder")}
              className={`w-full max-w-lg p-4 mt-2 mb-2 text-black bg-white rounded-md border-2 outline-none ${
                email.length > 0
                  ? isValid
                    ? "border-green-500"
                    : "border-red-500"
                  : "border-gray-300"
              }`}
              onChange={(event) => setEmail(event.target.value)}
            />

            <div className="flex flex-row items-center justify-start mt-4 mb-4">
              <input
                type="checkbox"
                className="w-8 h-8"
                name="privacy"
                checked={privacy}
                onChange={(event) => setPrivacy(event.target.checked)}
              />
              <label
                className="ml-2 text-md font-medium text-gray-900"
                htmlFor="privacy"
              >
                {t("email.privacy")}
              </label>
            </div>
            <div className="mb-4">
              <a
                href={dropConfig.privacyPolicy}
                target="_blank"
                rel="noreferrer"
                className="ml-2 text-md font-medium"
              >
                {t("email.privacy_link_text")}
              </a>
            </div>

            <button
              className={`mt-2 mb-2 btn btn-success ${
                !isValid || !privacy || isLoading
                  ? "btn-disabled text-black"
                  : ""
              } ${isLoading ? "loading" : ""}`}
              onClick={handleEmailSend}
            >
              {isLoading ? t("email.sending") : t("email.button")}
            </button>
          </Section>
        </div>
      </div>
    </>
  );
};
