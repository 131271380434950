import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount, useContractEvent, useContractRead } from "wagmi";
import { Section } from "../components/layout/Section";
import { Loader } from "../components/Loader";
import { truncateAddress } from "../utils/common";
import { useRight } from "../hooks/useRight";
import { useTranslation } from "react-i18next";
import { useDropConfig } from "../hooks/useDropConfig";
import { PendingTransactions } from "../components/PendingTransactions";
import { NFTCard } from "../components/NFTCard";
import { useTransactionWatcher } from "../hooks/useTransactionWatcher";
import Abi from "../abi/abi";
import { DownloadWalletModal } from "../components/DownloadWalletModal";
import { MdDownloadForOffline } from "react-icons/md";
import { FiExternalLink } from "react-icons/fi";
import { IoIosCopy } from "react-icons/io";
import { BigNumber } from "ethers";
import { useCopyToClipboard, usePreviousDistinct } from "react-use";
import { RequestStatus } from "../utils/types";
import { toast } from "react-toastify";
import { envs } from "../utils/envs";

interface WalletPageProps {}

export const WalletPage: React.FC<WalletPageProps> = () => {
  const navigate = useNavigate();
  const { address, connector: activeConnector, isConnecting } = useAccount();
  const { t } = useTranslation();
  const { event, key } = useParams();
  const { right } = useRight(key);
  const [showModal, setShowModal] = useState(false);

  const [_, copy] = useCopyToClipboard();

  const { dropConfig } = useDropConfig(event);

  const { data } = useTransactionWatcher(event, right?.address);

  const prevStatus = usePreviousDistinct(data?.status);

  const redeemedNow =
    prevStatus === RequestStatus.Pending &&
    data?.status === RequestStatus.Success;

  const { data: balanceOf, refetch: refetchContract } = useContractRead({
    address: dropConfig.contractAddress,
    abi: Abi,
    functionName: "balanceOf",
    args: [
      address || `0x0000000000000000000000000000000000000000`,
      BigNumber.from(dropConfig?.tokenId),
    ],
    watch: true,
    enabled: !!address,
  });

  useContractEvent({
    address: dropConfig.contractAddress,
    abi: Abi,
    eventName: "TransferSingle",
    listener: (_operator, _from, to, _tokenId, _amount) => {
      if (to.toLowerCase() !== address?.toLowerCase()) return;
      refetchContract();
    },
  });

  const ownedNFTs = useMemo(
    () =>
      balanceOf
        ? Array.from({ length: balanceOf.toNumber() }).map((_, i) => ({
            id: i,
            name: `${dropConfig?.nftName}`,
            description: dropConfig?.nftDescription,
            image: dropConfig?.nftImage,
            video: dropConfig?.nftVideo,
            downloadLink: dropConfig?.downloadLink,
            openseaLink: `${process.env["REACT_APP_OPENSEA_URL"]}/assets/matic/${dropConfig.contractAddress}/${dropConfig.tokenId}`,
          }))
        : [],
    [balanceOf, dropConfig]
  );

  const cols = useMemo(() => {
    if (!balanceOf) return 1;

    return balanceOf?.toNumber() > 1 ? 2 : 1;
  }, [balanceOf]);

  const handleCopyAddress = useCallback(() => {
    if (!address) return;

    copy(address);
    toast.success(t("wallet.copied") as string, { autoClose: 5000 });
  }, [address, copy, t]);

  const handleEtherscanLink = useCallback(() => {
    if (!address) return;

    window.open(`${envs.EXPLORER_URL}/address/${address}`, "_blank");
  }, [address]);

  return (
    <>
      <div className="flex flex-col items-center w-full text-cente text-primary">
        <Section>
          <div className="relative flex p-2 border-2 rounded-md">
            <div className="absolute text-2xl cursor-pointer w-full h-full flex justify-end pr-4">
              <FiExternalLink className="mr-2" onClick={handleEtherscanLink} />
              <IoIosCopy className="mr-2" onClick={handleCopyAddress} />
              {activeConnector?.id === "browserwallet" && (
                <MdDownloadForOffline onClick={() => setShowModal(true)} />
              )}
            </div>

            {address && <p>{truncateAddress(address, 8)}</p>}
          </div>
        </Section>
        <div
          className={`grid lg:grid-cols-${cols} sm:grid-cols-${cols} gap-4 w-full`}
        >
          {right?.address && event && !showModal && (
            <PendingTransactions data={data} redeemedNow={redeemedNow} />
          )}
        </div>
        <Section>
          <div className="justify-center tabs tabs-boxed align-center text-secondary">
            <span className="text-xl">{t("wallet.nft_title")}</span>
          </div>
        </Section>

        <div
          className={`grid lg:grid-cols-${
            ownedNFTs?.length > 1 ? 3 : 1
          } sm:grid-cols-${ownedNFTs?.length > 1 ? 3 : 1} gap-4`}
        >
          {!showModal && ownedNFTs?.length > 0
            ? ownedNFTs?.map((value: any) => (
                <NFTCard
                  key={value?.id}
                  name={value?.name}
                  description={value?.description}
                  url={value?.image}
                  video={value?.video}
                  downloadLink={value?.downloadLink}
                  shareOptions={dropConfig.shareOptions}
                  cta={
                    dropConfig?.hasPassport ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          navigate(`/${event}/${key}/passport`);
                        }}
                      >
                        {t("passport.cta")}
                      </button>
                    ) : null
                  }
                  openseaLink={value?.openseaLink}
                />
              ))
            : t("common.no_nft")}
        </div>
      </div>
      {isConnecting && <Loader />}
      {showModal && <DownloadWalletModal onClose={() => setShowModal(false)} />}
    </>
  );
};
