import { Navigate, useParams } from "react-router-dom";
import { useAccount } from "wagmi";

interface OnlyConnectedProps {
  children: React.ReactNode;
}

export const OnlyConnected: React.FC<OnlyConnectedProps> = ({ children }) => {
  const { connector: activeConnector } = useAccount();
  const { event, key } = useParams();

  const link = key ? `/${event}/${key}` : `/${event}`;

  if (!activeConnector) return <Navigate to={link} />;

  return <>{children}</>;
};
