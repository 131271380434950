import "./App.css";

import { HashRouter as Router, Route, Routes } from "react-router-dom";

import { Home } from "./pages/Home";
import { BaseLayout } from "./components/layout/BaseLayout";
import { WalletPage } from "./pages/WalletPage";
import { InvalidKey } from "./pages/InvalidKey";
import { InvalidEvent } from "./pages/InvalidEvent";

import { QueryClient, QueryClientProvider } from "react-query";
import { signInAnonymously } from "firebase/auth";
import { useAuthUser } from "@react-query-firebase/auth";
import { auth } from "./utils/firebase";
import { useEffect } from "react";
import { ClaimPage } from "./pages/ClaimPage";
import { OnlyConnected } from "./components/OnlyConnected";
import { MissingKey } from "./pages/MissingKey";
import { DigitalPassportPage } from "./pages/DigitalPassport";
import { EmailPage } from "./pages/Email";

const queryClient = new QueryClient();

function AnonymousLogin() {
  const { data: user } = useAuthUser("user", auth);

  useEffect(() => {
    if (user) return;

    signInAnonymously(auth).catch((error) =>
      console.log("Unable to login anonymously", error)
    );
  }, [user]);

  if (user) {
    console.log(`Logged in as anonymous user with id ${user.uid}`);
  }

  return <></>;
}

function App() {
  const { pathname } = window.location;

  // Eventually redirect to hash router
  useEffect(() => {
    if (pathname !== "/") {
      window.location.replace(`/#${pathname}`);
    }
  }, [pathname]);

  if (pathname !== "/") return <></>;

  return (
    <QueryClientProvider client={queryClient}>
      <AnonymousLogin />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <BaseLayout>
                <Home />
              </BaseLayout>
            }
          />
          <Route
            path="/:event/wallet"
            element={
              <BaseLayout>
                <OnlyConnected>
                  <WalletPage />
                </OnlyConnected>
              </BaseLayout>
            }
          />
          <Route
            path="/:event/email"
            element={
              <BaseLayout>
                <EmailPage />
              </BaseLayout>
            }
          />
          <Route
            path="/:event/:key/claim"
            element={
              <BaseLayout>
                <OnlyConnected>
                  <ClaimPage />
                </OnlyConnected>
              </BaseLayout>
            }
          />
          <Route
            path="/:event/:key/wallet"
            element={
              <BaseLayout>
                <OnlyConnected>
                  <WalletPage />
                </OnlyConnected>
              </BaseLayout>
            }
          />
          <Route
            path="/:event/:key/passport"
            element={
              <BaseLayout>
                <OnlyConnected>
                  <DigitalPassportPage />
                </OnlyConnected>
              </BaseLayout>
            }
          />
          <Route
            path="/:event/:key"
            element={
              <BaseLayout>
                <Home />
              </BaseLayout>
            }
          />
          <Route
            path="/:event"
            element={
              <BaseLayout>
                <Home />
              </BaseLayout>
            }
          />
          <Route
            path="/invalid-key"
            element={
              <BaseLayout>
                <InvalidKey />
              </BaseLayout>
            }
          />
          <Route
            path="/missing-key"
            element={
              <BaseLayout>
                <MissingKey />
              </BaseLayout>
            }
          />
          <Route
            path="/invalid-event"
            element={
              <BaseLayout>
                <InvalidEvent />
              </BaseLayout>
            }
          />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
