import { useTranslation } from "react-i18next";
import { RequestStatus } from "../utils/types";
import { StatusBadge } from "./StatusBadge";

interface PendingTransactionsProps {
  data: any;
  redeemedNow: boolean;
}

const PendingStatusses = [
  RequestStatus.Initial,
  RequestStatus.Pending,
  RequestStatus.Queued,
];

export const PendingTransactions: React.FC<PendingTransactionsProps> = ({
  data,
  redeemedNow,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row w-full pb-4">
      {(PendingStatusses.includes(data?.status) || redeemedNow) && (
        <table className="table w-full text-secondary">
          <tbody>
            <tr>
              <th>{t("common.id")}</th>
              <th>{t("common.status")}</th>
            </tr>
            <tr>
              <td>{data?.idempotencyKey?.slice(0, 10)}</td>
              <td>
                <StatusBadge status={data?.status} />
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {data?.status === RequestStatus.Success && !redeemedNow && (
        <table className="table w-full text-secondary">
          <tbody>
            <tr>
              <th>{t("common.already_claimed")}</th>
              {/* <td>
                <StatusBadge status={data?.status} />
              </td> */}
            </tr>
          </tbody>
        </table>
      )}

      {(!data || data?.status === RequestStatus.Error) && (
        <table className="flex table w-full min-h-4 text-secondary">
          <tbody>
            <tr>
              <th>{t("common.error")}</th>
              <td>
                <StatusBadge status={data?.status} />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};
