import { Wallet } from "ethers";
import { useMemo } from "react";
import { computeTree, hashLeaf } from "../utils/drop";

export function useProof(
  right?: Wallet,
  rights?: string[],
  eventName?: string
) {
  const data = useMemo(() => {
    if (!right || !rights) return;

    const storageKey = `${right.address}-${eventName}`;
    const cache = localStorage.getItem(storageKey);

    if (cache) {
      const parsedCache = JSON.parse(cache);

      return {
        address: parsedCache.address as string,
        rights: parsedCache.rights as string,
        proof: parsedCache.proof as string[],
        leaf: parsedCache.leaf as string,
        fromCache: true,
      };
    }

    const tree = computeTree(rights);

    const leaf = hashLeaf(right.address).toString("hex");
    const proof = tree.getHexProof(leaf);

    const result = {
      root: tree.getHexRoot(),
      leaf,
      proof,
      address: right.address,
      fromCache: false,
    };

    localStorage.setItem(storageKey, JSON.stringify(result));

    return result;
  }, [right, rights, eventName]);

  return data;
}
