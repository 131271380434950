// * Web3
import { createClient, configureChains } from "wagmi";
import { polygon, polygonMumbai } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { envs } from "./envs";
import { BrowserWalletConnector } from "./BrowserWalletConnector";

const { chains, provider } = configureChains(
  [envs.CHAIN === "mainnet" ? polygon : polygonMumbai],
  [alchemyProvider({ apiKey: envs.ALCHEMY_API_KEY })]
);

export const client = createClient({
  connectors() {
    return [
      new InjectedConnector({
        chains,
        options: { shimDisconnect: true },
      }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
        },
      }),
      new BrowserWalletConnector({
        chains,
        options: {
          localStorageKey: "mnemonic",
          provider: provider({ chainId: chains[0].id }),
          unlocked: true,
        },
      }),
    ];
  },
  provider,
});
