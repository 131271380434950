import { Footer } from "./Footer";
import { Header } from "./Header";
import { ExternalContainer } from "./ExternalContainer";
import { PageContent } from "./PageContent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface BaseLayoutProps {
  children: React.ReactNode;
}

export const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
  return (
    <ExternalContainer>
      <Header />
      <PageContent>{children}</PageContent>
      <Footer />
      <ToastContainer />
    </ExternalContainer>
  );
};
