export enum RequestStatus {
  Initial = "INITIAL",
  Queued = "QUEUED",
  Pending = "PENDING",
  Success = "SUCCESS",
  Error = "ERROR",
}

export type MintRequest = {
  idempotencyKey: string;
  signature: string;
  contractAddress: string;
  tokenRecipient: string;
  merkleProof: string[];
  status?: RequestStatus;
  txHash?: string;
  txReceipt?: any;
  nonce?: number;
  batchId?: string;
  createdAt?: number;
  updatedAt?: number;
};

type PassportInfo = {
  title: string;
  value: string;
};

export type DropConfig = {
  batchSize: number;
  batchTime: number;
  contractAddress: string;
  gasPrice: string;
  hasPassport?: boolean;
  headingType: "text" | "image";
  headingValue: string;
  nftDescription: string;
  nftDownloadLink?: string;
  nftImage: string;
  nftName: string;
  nftVideo?: string;
  passportInfo?: PassportInfo[];
  requireEmail?: boolean;
  rights: string[];
  tokenId: number;
  shareOptions?: {
    social: "linkedin" | "twitter" | "whatsapp" | "telegram" | "facebook";
  }[];
};
