import { useParams } from "react-router-dom";

interface ExternalContainerProps {
  children: React.ReactNode;
}

export const ExternalContainer: React.FC<ExternalContainerProps> = ({
  children,
}) => {
  const { event } = useParams();
  return (
    <div
      className="flex flex-col flex-1 min-h-full bg-neutral-content"
      data-theme={event}
    >
      {children}
    </div>
  );
};
