import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { collection, limit, query, where } from "firebase/firestore";
import { firestore } from "../utils/firebase";
import { useMemo } from "react";

export const useEmailWatcher = (
  eventName: string = "",
  email: string = "none",
  enabled: boolean = false
) => {
  const emailToCheck = useMemo(
    () => (enabled ? email : "none"),
    [email, enabled]
  );

  const queryEnabled = Boolean(eventName) && emailToCheck !== "none";

  const collectionRef = collection(firestore, "events", eventName, "emails");

  const result = useFirestoreQuery(
    [eventName, email],
    query(collectionRef, where("email", "==", emailToCheck), limit(1)),
    { subscribe: true },
    { enabled: queryEnabled }
  );

  const data = result.data?.docs[0]?.data();

  return { ...query, data };
};
