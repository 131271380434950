import { useTranslation } from "react-i18next";
import { Modal, ModalProps } from "./Modal";

interface PrivacyPolicyModalProps extends ModalProps {
  policy?: string;
}

export const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = (
  props
) => {
  const { t } = useTranslation();

  return (
    <Modal {...props}>
      <div className="flex flex-col items-center justify-center">
        <div className="mt-2 mb-2 text-2xl text-black">
          {t("privacy.title")}
        </div>

        {props?.policy && <p>{props.policy}</p>}

        <button className="mt-2 mb-2 btn btn-success" onClick={props?.onClose}>
          {t("privacy.cta")}
        </button>
      </div>
    </Modal>
  );
};
