import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { doc } from "firebase/firestore";
import { useMemo } from "react";
import { firestore } from "../utils/firebase";
import { RequestStatus } from "../utils/types";

export const useTransactionSender = (
  eventName: string = "",
  right: string = "none",
  signature?: string,
  contractAddress?: string,
  tokenRecipient?: string,
  merkleProof?: string[]
) => {
  const ref = doc(firestore, "events", eventName, "transactions", right);
  const { mutateAsync, mutate, ...rest } = useFirestoreDocumentMutation(ref);

  const claimToken = useMemo(() => {
    if (
      !eventName ||
      right === "none" ||
      !signature ||
      !contractAddress ||
      !tokenRecipient ||
      !merkleProof
    )
      return null;

    return async () =>
      mutateAsync({
        idempotencyKey: right,
        contractAddress,
        merkleProof,
        signature,
        tokenRecipient,
        status: RequestStatus.Initial,
      });
  }, [
    eventName,
    right,
    signature,
    contractAddress,
    tokenRecipient,
    merkleProof,
    mutateAsync,
  ]);

  return { ...rest, claimToken };
};
