import { Wallet } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import { Section } from "../components/layout/Section";
import { Loader } from "../components/Loader";
import { computeTree, getPrefixedMessage, hashLeaf } from "../utils/drop";
import { useRight } from "../hooks/useRight";
import { useDropConfig } from "../hooks/useDropConfig";
import { useTransactionSender } from "../hooks/useTransactionSender";
import { useTranslation } from "react-i18next";

interface ClaimPageProps {}

export const ClaimPage: React.FC<ClaimPageProps> = () => {
  const navigate = useNavigate();
  const { address, isConnecting } = useAccount();
  const { event, key } = useParams();
  const { t } = useTranslation();

  const { dropConfig } = useDropConfig(event);

  const { right, isFetched, redeemed } = useRight(
    key,
    dropConfig?.contractAddress,
    dropConfig?.tokenId
  );

  useEffect(() => {
    if (isFetched && redeemed) {
      navigate(`/${event}/${key}/wallet`);
    }
  }, [isFetched, redeemed, event, key, navigate]);

  const rights = useMemo(() => dropConfig?.rights, [dropConfig?.rights]);

  const [signature, setSignature] = useState("");

  useEffect(() => {
    if (!address || !right) return;

    async function sign(wallet: Wallet, address: string) {
      setSignature(await wallet.signMessage(getPrefixedMessage(address)));
    }

    sign(right, address);
  }, [address, right]);

  const data = useMemo(() => {
    if (!right || !rights) return;

    const tree = computeTree(rights);

    const leaf = hashLeaf(right.address);
    const proof = tree.getHexProof(leaf);

    return {
      root: tree.getHexRoot(),
      leaf,
      proof,
      address: right,
    };
  }, [right, rights]);

  const { claimToken } = useTransactionSender(
    event,
    right?.address,
    signature,
    dropConfig?.contractAddress,
    address,
    data?.proof
  );

  const handleClaim = useCallback(async () => {
    if (!claimToken) return;

    await claimToken();

    navigate(`/${event}/${key}/wallet`);
  }, [claimToken, event, key, navigate]);

  return (
    <>
      {isConnecting && <Loader />}
      <div className="flex overlay bg-opacity-60" />
      <div className="flex flex-col items-center w-full text-cente text-primary">
        <div className="flex flex-col items-center justify-center ">
          <Section>
            <h3 className="mb-5 text-3xl font-bold">{t("claim.text")}</h3>
          </Section>
          <Section>
            <div>
              {claimToken && data?.proof && data?.proof.length > 0 && (
                <button className="btn btn-primary" onClick={handleClaim}>
                  {t("claim.cta")}
                </button>
              )}
            </div>
          </Section>
        </div>
      </div>
    </>
  );
};
