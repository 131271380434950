import { BigNumber, Wallet } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useContractRead } from "wagmi";
import Abi from "../abi/abi";

export function useRight(
  key?: string,
  contractAddress?: `0x${string}`,
  tokenId?: number
) {
  const [loadingWallet, setLoadingWallet] = useState(Boolean(key));
  const [wallet, setWallet] = useState<Wallet>();
  const [error, setError] = useState<Error>();

  const walletAddress = useMemo(
    () =>
      (wallet?.address ||
        `0x0000000000000000000000000000000000000000`) as `0x${string}`,
    [wallet]
  );

  const readEnabled = Boolean(
    wallet?.address && contractAddress && tokenId !== undefined
  );

  const {
    data: redeemed,
    isLoading,
    isFetched,
  } = useContractRead({
    address: contractAddress,
    abi: Abi,
    functionName: "redeemed",
    args: [BigNumber.from(tokenId || 1), walletAddress],
    cacheOnBlock: readEnabled,
    enabled: readEnabled,
  });

  useEffect(() => {
    if (!key) return;
    setLoadingWallet(true);

    setTimeout(() => {
      try {
        setWallet(new Wallet(key));
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoadingWallet(false);
      }
    }, 0);
  }, [key]);

  return {
    right: wallet,
    isLoading: isLoading || loadingWallet,
    isFetched,
    error,
    redeemed,
  };
}
