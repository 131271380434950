import { useFirestoreDocument } from "@react-query-firebase/firestore";
import { doc } from "firebase/firestore";
import { firestore } from "../utils/firebase";

export const useTransactionWatcher = (
  eventName: string = "",
  id: string = "none"
) => {
  const ref = doc(firestore, "events", eventName, "transactions", id);
  const query = useFirestoreDocument(
    [eventName, id],
    ref,
    {
      subscribe: true,
    },
    { enabled: Boolean(eventName) && id !== "none" }
  );

  const data = query.data?.data();

  return { ...query, data };
};
