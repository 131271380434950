import { default as EventEmitter } from "eventemitter3";
import { providers, ethers, Wallet } from "ethers";

export type BrowserWalletProviderOptions = {
  network?: number | string;
  localStorageKey: string;
  unlocked: boolean;
  provider: ethers.providers.Provider;
};

type Events = {
  accountsChanged(accounts: string[]): void;
  chainChanged(chainId: number | string): void;
  disconnect(): void;
};
type Event = keyof Events;

export class BrowserWalletProvider extends providers.BaseProvider {
  events = new EventEmitter<Events>();

  private options: BrowserWalletProviderOptions;
  private browserWallet?: Wallet;

  constructor(options: BrowserWalletProviderOptions) {
    super(options.network ?? 1);
    this.options = options;
  }

  loadMnemonic() {
    try {
      const mnemonic: string = JSON.parse(
        localStorage.getItem(this.options.localStorageKey) || "{}"
      );
      return mnemonic;
    } catch (e) {
      console.log("no mnemonic");
      return null;
    }
  }

  async enable() {
    const mnemonic = this.loadMnemonic();

    if (!mnemonic) throw new Error("Browser wallet not ready");

    this.browserWallet = Wallet.fromMnemonic(mnemonic);

    const address = this.browserWallet.address;

    this.events.emit("accountsChanged", [address]);
    return [address];
  }

  async disconnect() {
    this.events.emit("disconnect");
    delete this.browserWallet;
  }

  async getAccounts() {
    const address = await this.browserWallet?.getAddress();
    if (!address) return [];
    return [address];
  }

  async getSigner() {
    if (!this.browserWallet) throw new Error("Browser wallet not ready");
    return this.browserWallet?.connect(this.options.provider);
  }

  async switchChain(chainId: number) {
    this.options.network = chainId;
    this.network.chainId = chainId;
    this.events.emit("chainChanged", chainId);
  }

  async watchAsset(_asset: {
    address: string;
    decimals?: number;
    image?: string;
    symbol: string;
  }) {
    return true;
  }

  on(event: Event, listener: providers.Listener) {
    this.events.on(event, listener);
    return this;
  }
  once(event: Event, listener: providers.Listener) {
    this.events.once(event, listener);
    return this;
  }
  removeListener(event: Event, listener: providers.Listener) {
    this.events.removeListener(event, listener);
    return this;
  }
  off(event: Event, listener: providers.Listener) {
    this.events.off(event, listener);
    return this;
  }

  toJSON() {
    return "<BrowserWalletProvider>";
  }

  walletExists() {
    return Boolean(this.loadMnemonic());
  }
}
