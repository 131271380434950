import { useFirestoreDocument } from "@react-query-firebase/firestore";
import { doc } from "firebase/firestore";
import { envs } from "../utils/envs";
import { firestore } from "../utils/firebase";
import { DropConfig } from "../utils/types";

function tryParse(data?: string) {
  if (!data) return null;

  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
}

export const useDropConfig = (eventName: string = "") => {
  const ref = doc(firestore, "events", eventName || "none");
  const { isLoading, error, data, isError, isFetched } = useFirestoreDocument(
    ["events", eventName],
    ref,
    {},
    { enabled: Boolean(eventName) }
  );

  const dropConfig = data?.data();

  return {
    isLoading,
    error,
    dropConfig: {
      ...dropConfig,
      nftImage: dropConfig?.nftImage as string,
      nftVideo: dropConfig?.nftVideo as string,
      nftDescription: dropConfig?.nftDescription as string,
      nftName: dropConfig?.nftName as string,
      contractAddress: dropConfig?.contractAddress,
      rights: tryParse(dropConfig?.rights) as string[],
      headingType: dropConfig?.headingType || "text",
      headingValue: dropConfig?.headingValue || envs.APP_TITLE,
      downloadLink: dropConfig?.nftDownloadLink,
      tokenId: dropConfig?.tokenId || 1,
      hasPassport: dropConfig?.hasPassport || false,
      passportInfo: dropConfig?.passportInfo
        ? JSON.parse(dropConfig?.passportInfo)
        : [],
      requireEmail: dropConfig?.requireEmail || false,
      privacyPolicy: dropConfig?.privacyPolicy || "",
      shareOptions:
        dropConfig?.shareOptions || ([] as DropConfig["shareOptions"]),
    },
    isError,
    isFetched,
  };
};
