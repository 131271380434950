import { RequestStatus } from "../utils/types";

interface StatusBadgeProps {
  status: RequestStatus;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ status }) => {
  let color;
  switch (status) {
    case RequestStatus.Initial:
    case RequestStatus.Pending:
    case RequestStatus.Queued:
      color = "yellow";
      break;
    case RequestStatus.Success:
      color = "green";
      break;
    default:
      color = "red";
  }

  return (
    <span className="badge" style={{ color: "#000", backgroundColor: color }}>
      {status}
    </span>
  );
};
