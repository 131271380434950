import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { doc } from "firebase/firestore";
import { useMemo } from "react";
import { firestore } from "../utils/firebase";
import { useLocalStorage } from "react-use";
import { RequestStatus } from "../utils/types";

export const useEmailSender = (
  eventName: string = "none",
  email: string = "none",
  privacy: boolean
) => {
  const ref = doc(firestore, "events", eventName, "emails", email || "none");
  const { mutateAsync, mutate, ...rest } = useFirestoreDocumentMutation(ref);

  const [storedEmail, setStoredEmail] = useLocalStorage("email");

  const sendEmail = useMemo(() => {
    if (!eventName || !email || !privacy || email === "none") return null;

    return async () => {
      const result = await mutateAsync({
        idempotencyKey: email,
        email,
        privacy,
        status: RequestStatus.Queued,
      });

      setStoredEmail(email);

      return result;
    };
  }, [eventName, email, privacy, mutateAsync, setStoredEmail]);

  return { ...rest, sendEmail, storedEmail };
};
