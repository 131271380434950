import { KNOBSLogo } from "../images";

interface PoweredByProps {}

export const PoweredBy: React.FC<PoweredByProps> = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full p-5 text-secondary">
      <div className="w-full">
        <span>Powered by</span>
      </div>
      <div className="flex items-center justify-center w-full space-x-4">
        <a href="https://knobs.it" target="_blank" rel="noopener noreferrer">
          <img src={KNOBSLogo} alt="Powered by KNOBS" />
        </a>
      </div>
    </div>
  );
};
