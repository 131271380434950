import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { collection, query, limit } from "firebase/firestore";
import { firestore } from "../utils/firebase";
import { getI18n } from "react-i18next";
import { useEffect } from "react";

export function useDynamicTranslations(eventName: string = "none") {
  const ref = query(
    collection(firestore, "events", eventName, "translations"),
    limit(5)
  );

  const { isLoading, error, data, isError, isFetched } = useFirestoreQuery(
    "translations",
    ref,
    {},
    { enabled: Boolean(eventName) }
  );

  const translations = data?.docs.map((doc) => ({
    language: doc.id,
    data: doc.data(),
  }));

  useEffect(() => {
    if (!translations) return;

    const i18n = getI18n();

    if (!i18n) return;

    for (const translation of translations) {
      i18n.addResourceBundle(
        translation.language,
        "translation",
        translation.data,
        true,
        true
      );
    }
  }, [translations]);

  return { isLoading, error, translations, isError, isFetched };
}
