import { useCallback, useMemo } from "react";
import { useAccount, useDisconnect } from "wagmi";
import { HiOutlineLogout } from "react-icons/hi";
import { useDropConfig } from "../../hooks/useDropConfig";
import { useParams } from "react-router-dom";
import { Loader } from "../Loader";

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const { connector: activeConnector } = useAccount();
  const { disconnect } = useDisconnect();
  const { event } = useParams();
  const { dropConfig, isLoading } = useDropConfig(event);

  const handleDisconnect = useCallback(() => {
    disconnect();
  }, [disconnect]);

  const heading = useMemo(
    () => ({
      type: dropConfig?.headingType,
      value: dropConfig?.headingValue,
    }),
    [dropConfig?.headingType, dropConfig?.headingValue]
  );

  return (
    <div className="container flex items-center justify-center h-24 p-5 mx-auto my-10">
      <div className="flex-1" />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex-5">
          {heading.type === "text" && (
            <h2 className="text-3xl font-bold text-primary">{heading.value}</h2>
          )}
          {heading.type === "image" && (
            <img
              className="max-w-xs sm:max-w-sm"
              src={heading.value}
              alt="heading title"
            />
          )}
        </div>
      )}
      <div className="flex items-center justify-end flex-1 text-3xl">
        {activeConnector && (
          <HiOutlineLogout
            className="flex cursor-pointer text-base-100"
            onClick={handleDisconnect}
          />
        )}
      </div>
    </div>
  );
};
