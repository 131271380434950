import { useTranslation } from "react-i18next";
import { Section } from "../components/layout/Section";

interface InvalidKeyProps {}

export const InvalidKey: React.FC<InvalidKeyProps> = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex overlay bg-opacity-60" />
      <div className="flex flex-col items-center justify-center w-full text-center text-primary">
        <Section>
          <p className="mb-5">{t("invalid_key.title")}</p>
        </Section>
        <div className="flex flex-col items-center justify-center"></div>
      </div>
    </>
  );
};
